import { createStore, unwrap } from "solid-js/store";
import { Atom, Cmp, Mod, api } from ":mods";
import { For, createEffect, createSignal, splitProps } from "solid-js";

import { AMaterialTaskContent, MaterialTaskDesignView } from "src/modules/courses/models";
import { createMemo } from "solid-js";

type RecievedPayload = AMaterialTaskContent<{
  image?: any;
  title?: string;
  sub_title?: string;
  quote?: string;
  description?: string;
  description$?: string;
}>;
type RequestPayload = AMaterialTaskContent<{
  title?: string;
  sub_title?: string;
  quote?: string;
  description?: string;
  description$?: string;
}>;

type RequestPayloadSection = Partial<RequestPayload[0]>;
type RequestPayloadSectionContent = Partial<RequestPayloadSection["content"]>;
type RequestPayloadSectionContentItem = Partial<RequestPayloadSectionContent[0]>;

export default function (props: MaterialTaskDesignView<RecievedPayload, RequestPayloadSectionContent>) {
  // const dataTopic = props.elements?.data;
  const content = createMemo(() => {
    return props.elements.data.content as RecievedPayload;
  });

  function onDataChange(idx: number, props: RequestPayloadSectionContentItem) {
    const items = content()[0].content as RequestPayloadSectionContent;
    items[idx] = { ...items[idx], ...props };
    //? this is where you process data before passing it to update
    // for (const item of items) {
    //   // const new_item = { ...item, ...props } as RequestPayloadSectionContentItem;
    //   if (typeof item.image !== "boolean") {
    //     item.image = false;
    //   }
    // }
    // pass data to update
    console.log("d :: ", items);
    onDataUpdate(items);
  }

  function onDataUpdate(data: RequestPayloadSectionContent) {
    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    props.events?.onDataUpdate?.(formData, data);
  }

  return (
    <form class="w-full px-2rem">
      <For each={content()}>
        {(section, index) => {
          return (
            <>
              <h1 class="text-39px font-700 mt-1.3rem mb-1rem">
                {section.details.title || section.details[0]?.title || "Mission / Vision Statement"}
              </h1>
              <section class="flex flex-wrap w-full gap-2rem pr-0.8rem">
                <For each={section.content}>
                  {(content, idx) => {
                    const titleLen = content.title?.split(" ").length;
                    const fontStyle =
                      titleLen <= 3
                        ? "text-63px leading-67px h-125%  w-full "
                        : "text-41px leading-43px h-125%  w-full";

                    return (
                      <>
                        {!!content.title && !!content.sub_title && (
                          <div class="w-full flex justify-content items-center gap-30px ">
                            <div class="h-30vh w-50% flex flex-col gap-17px">
                              <Atom.Tasks.Title
                                class={fontStyle}
                                elements={{
                                  title: content.title,
                                }}
                                options={{
                                  editable: props.options.editable,
                                }}
                                events={{
                                  onChange: (e) => onDataChange(idx(), { title: e.target.value }),
                                }}
                              />

                              <Atom.Tasks.Subtitle
                                class="w-full h-50%"
                                elements={{
                                  sub_title: content.sub_title,
                                }}
                                options={{
                                  editable: props.options.editable,
                                }}
                                events={{
                                  onChange: (e) => onDataChange(idx(), { sub_title: e.target.value }),
                                }}
                              />
                            </div>

                            <div class="h-30vh w-50% ">
                              <Atom.Tasks.Description
                                class="w-full h-full"
                                elements={{
                                  description: content.description,
                                }}
                                options={{
                                  editable: props.options.editable,
                                }}
                                events={{
                                  onChange: (e) => onDataChange(idx(), { description: e.target.value }),
                                }}
                              />
                            </div>
                          </div>
                        )}

                        {!!content.quote && (
                          <div class="w-full flex justify-content items-center gap-30px ">
                            <div class="h-30vh w-50%">
                              <Atom.Tasks.Quote
                                class="w-full h-full"
                                elements={{
                                  quote: content.quote,
                                }}
                                options={{
                                  editable: props.options.editable,
                                }}
                                events={{
                                  onChange: (e) => onDataChange(idx(), { quote: e.target.value }),
                                }}
                              />
                            </div>

                            <div class="h-30vh w-50% ">
                              <Atom.Tasks.Description
                                class="w-full h-full"
                                elements={{
                                  description: content.description,
                                }}
                                options={{
                                  editable: props.options.editable,
                                }}
                                events={{
                                  onChange: (e) => onDataChange(idx(), { description: e.target.value }),
                                }}
                              />
                            </div>
                          </div>
                        )}

                        {/* third row */}
                        {!(!!content.quote || !!content.title || !!content.image) && !!content.description && (
                          <div class="w-full h-157px">
                            <Atom.Tasks.Description
                              class="w-full h-full"
                              elements={{
                                description: content.description,
                              }}
                              options={{
                                editable: props.options.editable,
                              }}
                              events={{
                                onChange: (e) => onDataChange(idx(), { description: e.target.value }),
                              }}
                            />
                          </div>
                        )}

                        {!!content.image && (
                          <div class="flex justify-center items-center gap-30px w-full h-full my-17px  ">
                            <div class="w-45% h-30vh">
                              {!!content.image && (
                                <Atom.Tasks.Image
                                  class="w-full h-full"
                                  options={{
                                    editable: false,
                                  }}
                                  elements={{
                                    image: content.image,
                                  }}
                                />
                              )}
                            </div>
                            <div class="w-55% h-30vh">
                              {!!content.description$ && (
                                <Atom.Tasks.Description
                                  class="w-full h-full"
                                  elements={{
                                    description: content.description$,
                                  }}
                                  options={{
                                    editable: false,
                                  }}
                                  events={{
                                    onChange: (e) => onDataChange(idx(), { description: e.target.value }),
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    );
                  }}
                </For>
              </section>
            </>
          );
        }}
      </For>
    </form>
  );
}
